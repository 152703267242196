let modal = document.getElementById("mobile-menu");
let modalContent = document.getElementById("modal-content");
let aboutCompany = document.getElementById("about-company-menu");
let ourServices = document.getElementById("our-services-menu");
let btnOpen = document.getElementById("btn-open");
let btnClose = document.getElementById("btn-close");
let header = document.getElementById("section-header");

btnOpen.addEventListener("click", function () {
  modal.style.width = "100%";
  modalContent.style.width = "300px"
  header.style.display = "none"
})

btnClose.addEventListener("click", function () {
  modal.style.width = "0";
  modalContent.style.width = "0"
  header.style.display = "flex"
})
ourServices.addEventListener("click", function () {
  modal.style.width = "0";
  modalContent.style.width = "0"
  header.style.display = "flex"
})
aboutCompany.addEventListener("click", function () {
  modal.style.width = "0";
  modalContent.style.width = "0"
  header.style.display = "flex"
})

window.addEventListener("click", function (event) {
  if (event.target == modal) {
    modal.style.width = "0";
    modalContent.style.width = "0"
    header.style.display = "flex"
  }
})
